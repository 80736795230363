@import 'src/styles/gaps';
@import 'src/styles/colors';
@import 'src/styles/typography';
@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
	display: flex;
	flex-direction: column;
	gap: $gap-l;

	:global {
		.ant-form-item .ant-form-item-explain-error span {
			font-size: 12px !important;
		}
	}
}

.title {
	color: $blue-text;

	@include heading-s;
}

.label {
	color: $grey-text-5;

	@include text-m;
}

.field {
	display: flex;
	flex-direction: column;

	.labelDescription {
		color: $grey;
		margin-top: $gap-m-neg;
		transition: all 0.3s;
		margin-bottom: $gap-s;

		@include text-xs;
	}

	.innDataError {
		color: #e64343;
		margin-top: $gap-m-neg;
		transition: all 0.3s;
		margin-bottom: $gap-s;

		@include text-xs;
	}

	.fieldError {
		margin-top: $gap-m-neg;
		transition: all 0.3s;
	}

	.isLoadingINN {
		opacity: 0.5;
	}
}

.nameWrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 18px;
}

.switchBlock {
	display: flex;
	flex-direction: row;
	gap: $gap-s;

	> div > div {
		margin-bottom: 0;
	}
}

.switchDescription {
	color: $grey-text;

	@include text-m;
}

.link {
	color: $blue5;
}

.button {
	height: 48px;
	padding: $gap-s $gap-4xl;
	margin-top: $gap-l;
	@include text-m;
}

.modalTitle {
	margin-bottom: $gap-s;
	font-weight: 600;
}
.modalIntro {
	color: $grey-text;
	margin-bottom: $gap-s;
}
.modalButtons {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: $gap-s;
	margin-bottom: $gap-s;

	@include xs {
		flex-direction: column;
	}
}
.modalLink {
	width: 100%;
}

.modalButton {
	width: 100%;
	color: $blue10;
	&:hover {
		background-color: $blue10 !important;
		color: rgb(243, 246, 250) !important;
	}
}


.modalFooter {
	font-size: 14px;
	color: $grey-text;
}
.modalFooterLink {
	color: $blue5;
}
