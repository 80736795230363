$text-icons-accent: #14489d;
$background-secondary: #f3f6fa;
$background-tertiary: #edeef0;
$text-icons-primary: #0a2653;
$text-icons-secondary: #0a2653a3;

$text-icons-link: #4587e8;
$text-primary: #001022;
$text-icons-tetriary: #0a26534d;

$light-grey-hover: #366ea40f;

$text-description-grey: #6f7d96;

$background-footer: #103e8b;
$footer-link: #5390e9;

$blue-text: #0a2653;
$blue: #103e8b;
$blue5: #06519e;
$blue10: #14489d;
$blue100: #f2f5f7;

$white: #ffffff;

$grey: #9aa4b9;
$grey-text: #6f7d96;
$grey-text-5: #627491;
$grey-text-10: #0a2653a3;

$red: #e64343;
$red-bright: #e16464;

$green: #009b47;

$yellow-5: #fdf7e3;
