$gap-3: 3px;
$gap-xs: 6px;
$gap-9: 9px;
$gap-s: 12px;
$gap-16: 16px;
$gap-m: 18px;
$gap-l: 24px;
$gap-xl: 30px;
$gap-2xl: 36px;
$gap-3xl: 42px;
$gap-4xl: 48px;
$gap-5xl: 54px;
$gap-6xl: 60px;
$gap-7xl: 66px;
$gap-8xl: 72px;
$gap-3-neg: -3px;
$gap-xs-neg: -6px;
$gap-s-neg: -12px;
$gap-m-neg: -18px;
$gap-l-neg: -24px;
$gap-xl-neg: -30px;
$gap-2xl-neg: -36px;
$gap-3xl-neg: -42px;
$gap-4xl-neg: -48px;
$gap-5xl-neg: -54px;
$gap-6xl-neg: -60px;
$gap-7xl-neg: -66px;
$gap-8xl-neg: -72px;
