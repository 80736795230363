$fonts: '../fonts/';
$font-main: 'Lato';
$font-secondary: 'PTSans';
/* Lato */

@font-face {
	font-family: 'Lato';
	src:
		local('Lato Medium'),
		local('Lato-Medium'),
		url($fonts + 'Lato/Lato-Medium.woff2') format('woff2'),
		url($fonts + 'Lato/Lato-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Lato';
	src:
		local('Lato Regular'),
		local('Lato-Regular'),
		url($fonts + 'Lato/Lato-Regular.woff2') format('woff2'),
		url($fonts + 'Lato/Lato-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Lato';
	src:
		local('Lato Bold'),
		local('Lato-Bold'),
		url($fonts + 'Lato/Lato-Bold.woff2') format('woff2'),
		url($fonts + 'Lato/Lato-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Lato';
	src:
		local('Lato Heavy'),
		local('Lato-Heavy'),
		url($fonts + 'Lato/Lato-Heavy.woff2') format('woff2'),
		url($fonts + 'Lato/Lato-Heavy.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'PTSans';
	src:
		local('PTSans Regular'),
		url('../fonts/PTSans/PTSans-Regular.ttf') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'PTSans Bold';
	src:
		local('PTSans Bold'),
		url($fonts + 'PTSans/PTSans-Bold.ttf') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'PTSans Italic';
	src:
		local('PTSans Italic'),
		url($fonts + 'PTSans/PTSans-Italic.ttf') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'PTSans BoldItalic';
	src:
		local('PTSans BoldItalic'),
		url($fonts + 'PTSans/PTSans-BoldItalic.ttf') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}
