.react-dadata__container {
	position: relative
}

.react-dadata__input {
}

.react-dadata__suggestions {
	background-color: #fff;
	border-radius: 0.5rem;
	left: 0;
	list-style: none;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: absolute;
	right: 0;
	text-align: left;
	top: calc(100% + 8px);
	box-shadow: 0px 9px 111px rgba(0, 0, 0, 0.05), 0px 1.01364px 28.7341px rgba(0, 0, 0, 0.0308023);
	z-index: 10
}

.react-dadata__suggestion-note {
	color: #828282;
	font-size: 14px;
	padding: 10px 10px 5px
}

.react-dadata__suggestion {
	background: none;
	border: none;
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	font-size: 15px;
	text-align: left;
	width: 100%;

	min-height: 0;
	padding: 0;

	> div {
		min-height: 4rem;
		padding: 7px 10px;
		font-size: 1.6rem;
		text-align: left;
		width: 100%;
	}

	> div > mark {
		font-size: 1.6rem;
	}
}

.react-dadata__suggestion--line-through {
	text-decoration: line-through
}

.react-dadata__suggestion-subtitle {
	color: #777;
	font-size: 14px;
	margin-top: 4px
}

.react-dadata__suggestion-subtitle-item {
	display: inline-block;
	margin-bottom: 4px;
	margin-right: 16px;
}

.react-dadata__suggestion-subtitle-item:last-child {
	margin-right: 0
}

.react-dadata__suggestion--current {
	background-color: #F0F6FF;
}

.react-dadata__suggestion:hover {
	background-color: #F0F6FF;
}

.react-dadata mark {
	background: none
}

.react-dadata--highlighted {
	color: #0094ff
}
