@import './fonts';

@mixin accent-xs {
	/* Web Typoghaphy/Accent-XS */
	font-family: 'Lato';
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%; /* 16.8px */
}

@mixin accent-s {
	/* Web Typoghaphy/Accent-S */
	font-family: $font-main;
	font-size: 14px !important;
	font-style: normal;
	font-weight: 700;
	line-height: 120%;
}

@mixin accent-m {
	/* Web Typoghaphy/Accent-M */
	font-family: 'Lato';
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%; /* 19.2px */
}

@mixin accent-l {
	/* Web Typoghaphy/Accent-L */
	font-family: 'Lato';
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%; /* 21.6px */
}

@mixin accent-xl {
	/* Web Typoghaphy/Accent-XL */
	font-family: $font-main;
	font-size: 20px !important;
	font-style: normal;
	font-weight: 700;
	line-height: 120%; /* 24px */
}

@mixin text-xxs {
	font-family: 'Lato';
	font-size: 10px !important;
	font-style: normal;
	font-weight: 500;
	line-height: 140%; /* 14px */
}

@mixin text-xs {
	/* Web Typoghaphy/Text-XS */
	font-family: 'Lato';
	font-size: 12px !important;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 16.8px */
}

@mixin text-s {
	/* Web Typoghaphy/Text-S */
	font-family: 'Lato';
	font-size: 14px !important;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 19.6px */
}

@mixin text-m {
	/* Web Typoghaphy/Text-M */
	font-family: 'Lato';
	font-size: 16px !important;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 22.4px */
}

@mixin text-l {
	/* Web Typoghaphy/Text-L */
	font-family: 'Lato';
	font-size: 18px !important;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 25.2px */
}

@mixin text-xl {
	/* Web Typoghaphy/Text-XL */
	font-family: 'Lato';
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 28px */
}

@mixin text-2xl {
	/* Web Typoghaphy/Text-XL */
	font-family: 'Lato';
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 28px */
}

@mixin accent-2xl {
	/* Web Typoghaphy/Text-XL */
	font-family: 'Lato';
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%; /* 28px */
}

@mixin heading-xs {
	/* Web Typoghaphy/Heading-XS */
	font-family: 'Lato';
	font-size: 18px !important;
	font-style: normal;
	font-weight: 700;
	line-height: 110%; /* 27.5px */
}

@mixin heading-s {
	/* Web Typoghaphy/Heading-S */
	font-family: 'Lato';
	font-size: 25px !important;
	font-style: normal;
	font-weight: 700;
	line-height: 110%; /* 27.5px */
}

@mixin heading-m {
	/* Web Typoghaphy/Heading-M */
	font-family: 'Lato';
	font-size: 32px !important;
	font-style: normal;
	font-weight: 700;
	line-height: 110%; /* 35.2px */
}

@mixin heading-l {
	/* Web Typoghaphy/Heading-L */
	font-family: 'Lato';
	font-size: 41px;
	font-style: normal;
	font-weight: 700;
	line-height: 110%; /* 45.1px */
}

@mixin heading-xl {
	/* Web Typoghaphy/Headin - XL */
	font-family: 'Lato';
	font-size: 52px;
	font-style: normal;
	font-weight: 700;
	line-height: 110%; /* 57.2px */
}
