=lg
  @media (max-width: 1240px)
    @content

=md
  @media (max-width: 992px)
    @content

=sm
  @media (max-width: 768px)
    @content

=xs
  @media (max-width: 576px)
    @content

=xxs
  @media (max-width: 360px)
    @content

=no-select
  user-select: none
  pointer-events: none

=visually-hidden
  position: absolute
  clip: rect(0 0 0 0)
  width: 1px
  height: 1px
  margin: -1px
  overflow: hidden


@mixin line-clamp($lines) 
  display: -webkit-box
  -webkit-line-clamp: $lines
  -webkit-box-orient: vertical
  overflow: hidden
