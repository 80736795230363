@import '../../styles/mixins';

.input {
	&__tooltip {
		position: fixed;
		padding: 12px 16px;
		background-color: #fff;
		box-shadow:
			0px 1px 28.73411px 0 rgba(0, 0, 0, 0.03),
			0 9px 111px 0 rgba(0, 0, 0, 0.05);
		border-radius: 8px;
		pointer-events: none;
		opacity: 0;
		z-index: 1;
		transition: opacity 0.2s;

		&::before {
			content: '';
			position: absolute;
			top: -8px;
			left: 16px;
			width: 20px;
			height: 8px;
			background-image: url('/img/svg/tooltip-arrow.svg');
		}
	}

	&__container:hover .input__tooltip._disabled {
		opacity: 1;
	}

	&__container {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 0.8rem;
		position: relative;
		transition: 0.3s;

		&._disabled ._disabled ~ input:disabled {
			background-color: #f5f5f5;
			color: #9e9e9e;
		}

		.disabledInput {
			background-color: #f5f5f5 !important;
			color: #9e9e9e !important;
		}

		&.error {
			input {
				border: 1px solid #e64343;
			}
		}

		svg {
			position: absolute;
			right: 0.5rem;
			top: 50%;
			transform: translateX(-50%);
		}

		span {
			font-size: 1.4rem;
			font-weight: 400;
			line-height: 140%;

			color: rgba(10, 38, 83, 0.64);

			&.error-text {
				position: absolute;
				top: 100%;
				margin-top: 0.2rem;

				color: #e64343 !important;
			}
		}

		input {
			display: flex;
			height: 4.8rem;
			width: 100%;
			padding: 1.4rem;

			background: #f0f6ff;
			border-radius: 1rem;
			border: none;
			outline: none;

			font-family: 'Lato';
			font-style: normal;
			font-weight: 400;
			font-size: 1.6rem;
			line-height: 140%;
			letter-spacing: 0.01em;

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				/* display: none; <- Crashes Chrome on hover */
				-webkit-appearance: none;
				margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
			}

			&[type='number'] {
				-moz-appearance: textfield; /* Firefox */
			}

			&::placeholder {
				user-select: none;
				font-family: 'Lato';
				font-style: normal;
				font-weight: 400;
				font-size: 1.6rem;
				line-height: 140%;
				letter-spacing: 0.01em;

				color: rgba(10, 38, 83, 0.3);
			}
		}
	}
}
